// /src/components/common/PdfDetails.js
import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  forwardRef,
} from "react";
import Cookies from "js-cookie";
import PatientContext from "../../../context/PatientContext";
import "./index.css";
import ReactToPrint from "react-to-print";
import ApolloLogo from "../../common/img/Apollo_logo.svg";
import Apollo24 from "../../common/img/Apollo-247-Onl-1.png";
import ApolloImg from "../../common/img/download.png";
import { useNavigate } from "react-router-dom";

const PrintPdfDetails = forwardRef((props, ref) => {
  const [patientInfo, setPatientInfo] = useState({});
  const [attributeInfo, setAttributeInfo] = useState(props.PdfInfo);
  const { patientDetails } = useContext(PatientContext);
  const { contextPatientReport } = useContext(PatientContext);
  const [apidata, setAPI] = useState();

  useEffect(() => {
    setPatientInfo(patientDetails);
    setAttributeInfo(props.PdfInfo);
    const report = contextPatientReport.data.data;
    setAPI(report);
  }, [props.PdfInfo, contextPatientReport, patientDetails]);

  const renderCurrentDate = () => {
    const date = new Date();
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  let riskCardColor = "";

  if (apidata && apidata.risk === "High Risk")
    riskCardColor = "risk-card-bg-red";
  else if (apidata && apidata.risk === "Moderate Risk")
    riskCardColor = "risk-card-bg-yellow";
  else riskCardColor = "risk-card-bg-green";

  const loginDetails = JSON.parse(Cookies.get("login_details"));

  return (
    <div className="pdfreportform" ref={ref}>
      <div className="pdfpage">
        <div className="pdf-header-conatiner">
          <img
            className="pdf-apollo-logo"
            src={ApolloLogo}
            alt="Apollo_logo.png"
          />
          <h1 className="RiskScoreHeading">AILF Risk Score Report</h1>
          <p></p>
        </div>
        <div className="pdf-report-patient-detials-container">
          <ul className="user-details">
            {Object.entries(patientInfo).map(
              ([key, value]) =>
                key !== "email" && (
                  <li key={key}>
                    <p className="pateint-info-heading">
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </p>
                    :<p className="patient-info-value">{value}</p>
                  </li>
                )
            )}
          </ul>
          <div className="info-container">
            <p className="info-title width-65">Date of Report</p>
            <p className="info-colon">:</p>
            <p className="info-value">{renderCurrentDate()}</p>
          </div>
        </div>
        <div className="attributes-info-container">
          <p className="box-heading attribute-info-bg">Atrributes</p>
          <ul className="pdf-attributes-fields-container">
            {Object.entries(attributeInfo).map(
              ([key, value]) =>
                value !== "" && (
                  <li key={key} className="field-container">
                    <p className="field-text">
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </p>
                    <p className="field-date-container attribute-border">
                      {value}
                    </p>
                  </li>
                )
            )}
          </ul>
        </div>

        {apidata && (
          <div>
            <div className="pdf-report-heading-conatiner">
              <div className="left-line"></div>
              <h1 className="report-sub-heading liver-color">
                PATIENT RISK SCORE
              </h1>
              <div className="right-line"></div>
            </div>
            <div className="risk-box-container">
              <div className="each-risk-box liver-each-score-box">
                <p className="score-box-heading">Risk Status</p>
                <div className={` ${riskCardColor} score-box`}>
                  {apidata.risk}
                </div>
              </div>
              <div className="each-risk-box liver-each-score-box">
                <p className="score-box-heading">Patient Score</p>
                <div className="score-box font-color">
                  Probability of High Fibrosis (F2 - F4) -{" "}
                  <span>{apidata.risk_score}%</span>
                </div>
              </div>
              <div className="each-risk-box liver-each-score-box">
                <p className="score-box-heading">AI Score</p>
                <div className="score-box font-color">
                  AI Score (1 - 10) [F2 - F4] Score -{" "}
                  {(apidata.predicted_score * 10).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        )}
        <footer className="footer-container">
          <p className="footer-text mt-auto">
            This report is accessed by {loginDetails.executive}
          </p>
          <div className="date-pageNo-container">
            <div className="date-time-container">
              <p className="date-text">Date: {renderCurrentDate()}</p>
            </div>
            <p className="page-no-text">Page 1/3</p>
          </div>
        </footer>
      </div>
      <div className="pdfpage">
        <div className="pdf-report-patient-detials-container">
          <ul className="user-details">
            {Object.entries(patientInfo).map(
              ([key, value]) =>
                key !== "email" && (
                  <li key={key}>
                    <p className="pateint-info-heading">
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </p>
                    :<p className="patient-info-value">{value}</p>
                  </li>
                )
            )}
          </ul>
          <div className="info-container">
            <p className="info-title width-65">Date of Report</p>
            <p className="info-colon">:</p>
            <p className="info-value">{renderCurrentDate()}</p>
          </div>
        </div>
        {apidata && (
          <>
            <div className="pdf-report-form">
              <div className="">
                <div className="">
                  <div className="pdf-report-heading-conatiner">
                    <div className="left-line"></div>
                    <h1 className="report-sub-heading liver-color">
                      RECOMMENDED PROTOCOL
                    </h1>
                    <div className="right-line"></div>
                  </div>
                  <p className="report-recommended-list-item-heading">
                    LAB INVESTIGATION
                  </p>
                  <p className="report-recommended-list-item-value">
                    {apidata.lab_investigation}
                  </p>
                  <p className="report-recommended-list-item-heading">
                    {" "}
                    DIAGNOSTICS AND IMAGING{" "}
                  </p>
                  <p className="report-recommended-list-item-value">
                    {apidata.diagnostics_imaging}
                  </p>
                  <p className="report-recommended-list-item-heading">
                    REFERRAL
                  </p>
                  <p className="report-recommended-list-item-value">
                    {apidata.referral_to_consultant}
                  </p>
                  <p className="report-recommended-list-item-heading">
                    TREATMENT GOALS
                  </p>
                  <p className="report-recommended-list-item-value">
                    {apidata.treatment_goals}
                  </p>
                  <p className="report-recommended-list-item-heading">
                    EDUCATE ON
                  </p>
                  <p className="report-recommended-list-item-value">
                    {apidata.educate_on}
                  </p>
                  <p className="report-recommended-list-item-heading">
                    TEST FOLLOW UP
                  </p>
                  <p className="report-recommended-list-item-value">
                    {apidata.tests_follow_up}
                  </p>
                  <div className="pdf-logos-container">
                    <img
                      className="pdf-footer-logo"
                      src={Apollo24}
                      alt="apollo24/7.png"
                    />
                    <img
                      className="pdf-footer-logo"
                      src={ApolloImg}
                      alt="askApollo.png"
                    />
                  </div>
                  <footer className="footer-container">
                    <p className="footer-text mt-auto">
                      This report is accessed by {loginDetails.executive}
                    </p>
                    <div className="date-pageNo-container">
                      <div className="date-time-container">
                        <p className="date-text">Date: {renderCurrentDate()}</p>
                      </div>
                      <p className="page-no-text">Page 3/3</p>
                    </div>
                  </footer>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

const LiverPdfDetails = (props) => {
  const componentRef = useRef();
  const navigate = useNavigate();

  const gotohome = () => {
    navigate("/");
  };

  return (
    <div className="pdf-report-button">
      <PrintPdfDetails ref={componentRef} PdfInfo={props.PdfInfo} />
      <div className="report-buttons-container">
        <button className="button home-button mr-20" onClick={gotohome}>
          Home
        </button>
        <ReactToPrint
          trigger={() => <button className="button pdf-button">Get Pdf</button>}
          content={() => componentRef.current}
        />
      </div>
    </div>
  );
};

export default LiverPdfDetails;

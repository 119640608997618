// /src/components/common/PdfDetails.js
import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  forwardRef,
} from "react";
import PatientContext from "../../../context/PatientContext";
import ReactToPrint from "react-to-print";
import ApolloLogo from "../../common/img/Apollo_logo.svg";
import Cookies from "js-cookie";
import Apollo24 from "../../common/img/Apollo-247-Onl-1.png";
import ApolloImg from "../../common/img/download.png";
import { useNavigate } from "react-router-dom";

const PrintPdfDetails = forwardRef((props, ref) => {
  const [patientInfo, setPatientInfo] = useState({});
  const [attributeInfo, setAttributeInfo] = useState(props.PdfInfo);
  const { patientDetails } = useContext(PatientContext);
  const { contextPatientReport } = useContext(PatientContext);
  const [apidata, setAPI] = useState();

  useEffect(() => {
    setPatientInfo(patientDetails);
    setAttributeInfo(props.PdfInfo);
    const report = contextPatientReport.data;
    setAPI({
      activity: report.activity,
      nutrition: report.nutrition,
      referralToConsultant: report.referral_to_consultant,
      risk: report.risk,
      score: report.score,
      testsFollowUp: report.tests_followup,
      treatmentGoals: report.treatment_goal,
      labInvestigation: report.lab_investigation,
    });
  }, [patientDetails, props.PdfInfo, contextPatientReport]);

  const renderCurrentDate = () => {
    const date = new Date();
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  let riskCardColor = "";

  if (apidata && apidata.risk === "High Risk")
    riskCardColor = "risk-card-bg-red";
  else if (apidata && apidata.risk === "Moderate Risk")
    riskCardColor = "risk-card-bg-yellow";
  else riskCardColor = "risk-card-bg-green";

  const loginDetails = JSON.parse(Cookies.get("login_details"));

  return (
    <div className="pdfreportform" ref={ref}>
      <div className="pdfpage">
        <div className="pdf-header-conatiner">
          <img
            className="pdf-apollo-logo"
            src={ApolloLogo}
            alt="Apollo_logo.png"
          />
          <h1 className="RiskScoreHeading">Prediabetes Risk Score Report</h1>
          <p></p>
        </div>
        <div className="pdf-report-patient-detials-container">
          <ul className="user-details">
            {Object.entries(patientInfo).map(
              ([key, value]) =>
                key !== "email" && (
                  <li key={key}>
                    <p className="pateint-info-heading">
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </p>
                    :<p className="patient-info-value">{value}</p>
                  </li>
                )
            )}
          </ul>
          <div className="info-container">
            <p className="info-title width-65">Date of Report</p>
            <p className="info-colon">:</p>
            <p className="info-value">{renderCurrentDate()}</p>
          </div>
          <footer className="footer-container">
            <p className="footer-text mt-auto">
              This report is accessed by {loginDetails.executive}
            </p>
            <div className="date-pageNo-container">
              <div className="date-time-container">
                <p className="date-text">Date: {renderCurrentDate()}</p>
              </div>
              <p className="page-no-text">Page 1/3</p>
            </div>
          </footer>
        </div>
        <div className="attributes-info-container">
          <p className="box-heading attribute-info-bg">Atrributes</p>
          <ul className="pdf-attributes-fields-container">
            {Object.entries(attributeInfo).map(
              ([key, value]) =>
                value !== "" && (
                  <li key={key} className="field-container">
                    <p className="field-text">
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </p>
                    <p className="field-date-container attribute-border">
                      {value}
                    </p>
                  </li>
                )
            )}
          </ul>
        </div>
        {apidata && (
          <div>
            <div className="pdf-report-heading-conatiner">
              <div className="left-line"></div>
              <h1 className="report-sub-heading">PATIENT RISK SCORE</h1>
              <div className="right-line"></div>
            </div>
            <div className="risk-box-container">
              <div className="each-risk-box">
                <p className="score-box-heading">Risk Status</p>
                <div className={` ${riskCardColor} score-box`}>
                  {apidata.risk}
                </div>
              </div>
              <div className="each-risk-box">
                <p className="score-box-heading">Patient Score</p>
                <div className="score-box font-color">{apidata.score}</div>
              </div>
            </div>
            <p className="font-size">
              Note:{" "}
              <span className="">
                The risk status is computed with the Machine Learning Algorithm
                and categorised based on the individual’s chances of Prediabetes
                / Diabetes. It also provides a score based on the probability of
                the risk status.
              </span>
            </p>
            <div className="pdf-report-heading-conatiner">
              <div className="left-line"></div>
              <h1 className="report-sub-heading">RECOMMENDED PROTOCOL</h1>
              <div className="right-line"></div>
            </div>
            <p className="report-recommended-list-item-heading">
              LAB INVESTIGATION
            </p>
            <p className="report-recommended-list-item-value">
              {apidata.labInvestigation}
            </p>
          </div>
        )}
        <footer className="footer-container">
          <p className="footer-text mt-auto">
            This report is accessed by {loginDetails.executive}
          </p>
          <div className="date-pageNo-container">
            <div className="date-time-container">
              <p className="date-text">Date: {renderCurrentDate()}</p>
            </div>
            <p className="page-no-text">Page 1/2</p>
          </div>
        </footer>
      </div>
      <div className="pdfpage">
        <div className="pdf-report-patient-detials-container">
          <ul className="user-details">
            {Object.entries(patientInfo).map(
              ([key, value]) =>
                key !== "email" && (
                  <li key={key}>
                    <p className="pateint-info-heading">
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </p>
                    :<p className="patient-info-value">{value}</p>
                  </li>
                )
            )}
          </ul>
          <div className="info-container">
            <p className="info-title width-65">Date of Report</p>
            <p className="info-colon">:</p>
            <p className="info-value">{renderCurrentDate()}</p>
          </div>
        </div>
        {apidata && (
          <>
            <div className="pdf-report-form">
              <div className="">
                <div className="">
                  <p className="report-recommended-list-item-heading">
                    Activity{" "}
                  </p>
                  <p className="report-recommended-list-item-value">
                    {apidata.activity}
                  </p>
                  <p className="report-recommended-list-item-heading">
                    Nutrition
                  </p>
                  <p className="report-recommended-list-item-value">
                    {apidata.nutrition}
                  </p>
                  <p className="report-recommended-list-item-heading">
                    Referral to consultant
                  </p>
                  <p className="report-recommended-list-item-value">
                    {apidata.referralToConsultant}
                  </p>
                  <p className="report-recommended-list-item-heading">
                    TREATMENT GOALS
                  </p>
                  <p className="report-recommended-list-item-value">
                    {apidata.treatmentGoals}
                  </p>
                  <p className="report-recommended-list-item-heading">ADVICE</p>
                  <p className="report-recommended-list-item-value">
                    {apidata.advice}
                  </p>
                  <div className="">
                    <h1 className="report-recommended-list-item-heading">
                      DISCLAIMER
                    </h1>

                    <ul className="report-recommended-list-item-value">
                      <li className="">
                        This is not a diagnostic tool and it does not guarantee
                        the accuracy of the result and cannot be independently
                        acted upon.
                      </li>
                      <li className="">
                        This Risk score and Clinical Algorithm is a general
                        guideline for Physicians. Any additional laboratory
                        investigations, Diagnostic Imaging, Treatment or Patient
                        Education related to lifestyle management is under
                        Physician’s or Endocrinologist’s discretion.
                      </li>
                      <li className="">
                        To ensure the information in the report is up to date,
                        accurate and correct, Doctor shall be consulted for
                        interpretation of the report.
                      </li>
                      <li className="">
                        Apollo Hospitals and its Staff neither offers any
                        assurance on the information made available nor will be
                        liable for any loss or damage as the said report is
                        based on the Prediabetes Risk Score without any
                        intervention from their side.
                      </li>
                      <li className="">
                        By usage of Prediabetes Risk Score, it is deemed that
                        beneficiary of this service has agreed to get the same
                        done at his own risk and further agrees with this
                        disclaimer without any limitation or any clauses or
                        sub-clauses.
                      </li>
                      <li className="">
                        Note on Uncontrolled / Gestational Diabetes and PCOD -
                        The current model doesn’t account for (Exclusion
                        Criteria) –
                      </li>
                      <ul className="report-recommended-list-item-value">
                        <li className="">
                          Uncontrolled and Undiagnosed Diabetes
                        </li>
                        <li className="">Gestational Diabetes</li>
                        <li className="">Polycystic Ovarian Disease (PCOD)</li>
                        <li className="">
                          Previously diagnosed and under treatment Diabetes
                          mellitus
                        </li>
                      </ul>
                    </ul>
                    <div className="pdf-logos-container">
                      <img
                        className="pdf-footer-logo"
                        src={Apollo24}
                        alt="apollo24/7.png"
                      />
                      <img
                        className="pdf-footer-logo"
                        src={ApolloImg}
                        alt="askApollo.png"
                      />
                    </div>
                    <footer className="footer-container">
                      <p className="footer-text mt-auto">
                        This report is accessed by {loginDetails.executive}
                      </p>
                      <div className="date-pageNo-container">
                        <div className="date-time-container">
                          <p className="date-text">
                            Date: {renderCurrentDate()}
                          </p>
                        </div>
                        <p className="page-no-text">Page 2/2</p>
                      </div>
                    </footer>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

const PrediabetesPdfDetails = (props) => {
  const componentRef = useRef();
  const navigate = useNavigate();

  const gotohome = () => {
    navigate("/");
  };

  return (
    <div className="pdf-report-button">
      <PrintPdfDetails ref={componentRef} PdfInfo={props.PdfInfo} />
      <div className="report-buttons-container">
        <button className="button home-button mr-20" onClick={gotohome}>
          Home
        </button>
        <ReactToPrint
          trigger={() => <button className="button pdf-button">Get Pdf</button>}
          content={() => componentRef.current}
        />
      </div>
    </div>
  );
};

export default PrediabetesPdfDetails;

import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import SidebarOverlay from "../../Sidebar";

const Input = ({
  name,
  placeholder,
  type,
  options = [],
  value,
  units,
  onChange,
  onBlur,
  description,
  readOnly,
  label,
  id,
  isRequired,
  ranges,
  required,
  patient_required,
  formErrors,
  buttonClicked,
  img,
  color,
  ischeckbox,
}) => {
  const [selectedGender, setSelectedGender] = useState("");
  const [activeId, setActiveId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    options.find((option) => option.value === value)?.name || "Select"
  );
  const dropdownRef = useRef(null);

  const toggleSidebar = (id) => {
    setActiveId(activeId === id ? null : id);
  };

  const handleCheckboxChange = (event, name) => {
    onChange(name, event.target.value);
  };

  const handleGenderClick = (key, optionValue, color) => {
    setSelectedGender(optionValue);
    onChange(key, optionValue);
  };

  const handleFocus = () => {
    console.log(patient_required, "patient_required");
    if (patient_required === "yes") {
      const ErrorEl = document.getElementById(`${id}_error`);
      const element = document.getElementById(id);
      console.log(ErrorEl, "ErrorEl");
      console.log(element, "element");

      if (ErrorEl !== null) {
        element.style.border = "1px solid rgba(0, 0, 0, 0.2)";
        ErrorEl.style.animation = "error-fade-out 700ms ease";
        setTimeout(() => {
          ErrorEl.style.display = "none";
        }, 650);
      }
    }
  };

  const handleFieldError = (event) => {
    const ErrorEl = document.getElementById(`${id}_error`);
    const element = document.getElementById(id);
    const patient_element = event.target.value;
    const elementValue = parseInt(event.target.value);
    if (ranges) {
      if (!(elementValue >= ranges[0] && elementValue <= ranges[1])) {
        element.style.border = "1px solid rgb(255, 79, 9)";
        ErrorEl.style.display = "block";
        ErrorEl.style.animation = "error-fade-in 700ms ease";
        event.target.value = "";
      } else {
        element.style.border = "1px solid rgba(0, 0, 0, 0.2)";
        ErrorEl.style.animation = "error-fade-out 700ms ease";
        setTimeout(() => {
          ErrorEl.style.display = "none";
        }, 650);
      }
    } else if (patient_required === "yes") {
      if (patient_element === "") {
        element.style.border = "1px solid rgb(255, 79, 9)";
        ErrorEl.style.display = "block";
        ErrorEl.style.animation = "error-fade-in 700ms ease";
        event.target.value = "";
      } else {
        element.style.border = "1px solid rgba(0, 0, 0, 0.2)";
        ErrorEl.style.animation = "error-fade-out 700ms ease";
        setTimeout(() => {
          ErrorEl.style.display = "none";
        }, 650);
      }
    }
  };
  if (onChange === undefined) {
    const empty = () => {};
    onChange = empty;
  }

  const handleSelect = (item, itemValue) => {
    setSelectedItem(item);
    onChange(name, itemValue);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (type === "radio") {
    if (name === "gender")
      return (
        <div className="radio_box_in_input_component inputbox">
          <label htmlFor={id}>
            {required === "yes" && (
              <span>
                <img className="star-icon" src="asterisk.svg" alt="star.svg" />
              </span>
            )}
            {label}
          </label>
          <div className="button-group">
            {options.map((option) => (
              <div key={option.name}>
                <button
                  type="button"
                  onClick={() => handleGenderClick(name, option.value, color)}
                  style={{
                    backgroundColor:
                      selectedGender === option.value
                        ? "rgb(103, 84, 150)"
                        : "white",
                    color: selectedGender === option.value ? "white" : "black",
                  }}
                >
                  {option.name}
                </button>
              </div>
            ))}
          </div>
          {formErrors[name] && buttonClicked && (
            <p className="form-validation-error-text">Please enter {label}</p>
          )}
        </div>
      );
    else
      return (
        <div className="radio_box_in_input_component inputbox">
          <label htmlFor={id}>
            {required === "yes" && (
              <span>
                <img className="star-icon" src="asterisk.svg" alt="star.svg" />
              </span>
            )}
            {label}
          </label>
          <div className="optionsforRadioBox">
            {options.map((option) => (
              <div className="anRadioOptionInINputcomponent" key={option.value}>
                <input
                  type="radio"
                  name={name}
                  id={id + option.name}
                  value={option.value}
                  checked={value === option.value}
                  onChange={(e) => onChange(name, e.target.value)}
                  onBlur={onBlur}
                  className="radio-button"
                />
                <span className="c-radio"></span>
                <label
                  className="lable_in_input_component radio_lable_in_input_component"
                  htmlFor={id + option.name}
                >
                  {option.name}
                </label>
              </div>
            ))}
          </div>
          {formErrors[name] && buttonClicked && (
            <p className="form-validation-error-text">Please enter {label}</p>
          )}
        </div>
      );
  }

  if (type === "select") {
    return (
      <div className="select_box_in_input_component inputbox">
        <label htmlFor={name} className="definition-label-container">
          {required === "yes" && (
            <span>
              <img className="star-icon" src="asterisk.svg" alt="star.svg" />
            </span>
          )}
          {label}
          {description && (
            <div className="description-info-button-container">
              <button
                type="button"
                onClick={() => toggleSidebar(id)}
                className="description-bar"
              >
                i
              </button>
              {activeId === id && (
                <SidebarOverlay
                  id={id}
                  description={description}
                  activeId={activeId}
                  name={label}
                  setActiveId={setActiveId}
                  toggleSidebar={toggleSidebar}
                />
              )}
            </div>
          )}
        </label>
        <div className="custom-dropdown" ref={dropdownRef}>
          <div className="dropdown-header" onClick={toggleDropdown}>
            {img && (
              <img src={img} alt="contact-icon" className="select-icons" />
            )}
            <span className="selected-item">{selectedItem}</span>
            <span className="dropdown-arrow">
              {isOpen ? "\u25BC" : "\u25B2"}
            </span>
          </div>
          <ul className={`dropdown-list ${isOpen ? "show" : ""}`}>
            {options.map((option) => (
              <li
                key={option.value}
                className={`dropdown-item ${
                  option.name === selectedItem ? "active" : ""
                }`}
                onClick={() => handleSelect(option.name, option.value)}
              >
                {option.name}
              </li>
            ))}
          </ul>
        </div>
        {formErrors[name] && buttonClicked && (
          <p className="form-validation-error-text font-size">
            Please enter {label}
          </p>
        )}
      </div>
    );
  }

  if (type === "checkbox") {
    return (
      <div className="checkbox-input-box checkbox_input_box_in_input_component">
        <div className="checkbox-container">
          <input
            name={name}
            required={isRequired}
            id={id}
            type={type}
            value={value}
            onChange={(event) => handleCheckboxChange(event, name)}
            className="checkbox-input"
            checked={ischeckbox}
          />

          <span className="custom-checkbox">
            <img
              src="checkmark-outline-white.svg"
              alt="checkmark-outline-white"
            />
          </span>
        </div>

        <div className="inputbox checkbox_lable_box_input_in_input_component">
          <label
            className="lable_in_input_component checkbox_lable_in_input_component"
            htmlFor={id}
          >
            {value}
          </label>
        </div>
      </div>
    );
  }

  if (type === "date") {
    return (
      <div className="inputbox">
        <label htmlFor={name}>
          {required === "yes" && (
            <span>
              <img className="star-icon" src="asterisk.svg" alt="star.svg" />
            </span>
          )}
          {label}
          {units && `(${units})`}
        </label>
        <input
          name={name}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
          onBlur={onBlur}
          className="date_in_input_component"
          id={id}
        />
      </div>
    );
  }

  return (
    <div className="inputbox description-input-container">
      <div className="text_input_in_input_component_container" id={id}>
        {img && <img src={img} alt="contact-icon" className="icons-format" />}
        <div className="text_name_and_icon_container">
          <input
            name={name}
            placeholder={ranges ? `${ranges[0]} - ${ranges[1]}` : ""}
            type={type}
            value={value}
            onChange={(e) => onChange(name, e.target.value)}
            className="text_input_in_input_component"
            readOnly={readOnly}
            onBlur={(e) => {
              handleFieldError(e);
            }}
            onFocus={handleFocus}
            id={id}
          />
          <label htmlFor={name}>
            {required === "yes" && (
              <span>
                <img className="star-icon" src="asterisk.svg" alt="star.svg" />
              </span>
            )}
            {label}
            {units && `(${units})`}
          </label>
        </div>
      </div>
      {description && (
        <div>
          <button
            type="button"
            onClick={() => toggleSidebar(id)}
            className="description-bar"
          >
            i
          </button>
          {activeId === id && (
            <SidebarOverlay
              id={id}
              description={description}
              activeId={activeId}
              name={label}
              setActiveId={setActiveId}
              toggleSidebar={toggleSidebar}
            />
          )}
        </div>
      )}
      {patient_required && (
        <span id={`${id}_error`} className="age-error-text">
          Please enter a valid {label}
        </span>
      )}
      {formErrors[name] && buttonClicked && (
        <span className="form-error-text-valid">
          Please enter a valid {label}
        </span>
      )}
    </div>
  );
};

export default Input;
